import React from 'react'

import Loading from '../images/ic-loading.gif'

export default function IconLoading({
  width = 40,
  height = 40,
}: {
  width?: number
  height?: number
}) {
  return <img src={Loading} alt="Carregando" width={width} height={height} />
}
