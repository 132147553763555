/* eslint-disable prefer-const */
export const convertMilliseconds = (milliseconds: number) => {
  let day; let hour; let minute; let
    seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds %= 60;
  hour = Math.floor(minute / 60);
  minute %= 60;
  day = Math.floor(hour / 24);
  hour %= 24;
  return {
    day,
    hour,
    minute,
    seconds,
  };
};

export const getHoursDiference = (startDate: string) => {
  try {
    const diffMilliseconds = Math.abs(new Date(startDate).getTime() - new Date().getTime());
    const payload = convertMilliseconds(diffMilliseconds);
    if (payload.day < 0) return 'ongoing';
    if (payload.day > 0) return `${payload.day}d`;
    if (payload.day === 0) return `${payload.hour}h`;
    return '';
  } catch {
    return '';
  }
};
