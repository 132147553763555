import React from 'react'
import classNames from 'classnames'
import DateFormatter from '@/components/DateFormatter/DateFormatter'
import IconSymplaPlay from '@/icons/social/iconSymplaPlay'
import { SearchEvent } from './searchEvent'
import { eventType } from '@/constante/event'
import * as styles from './EventCard.css'
import { Button } from '@/components/Button'
import IconReport from '@/icons/communication/iconReport'

const DEFAULT_IMAGE = 'https://d1gkntzr8mxq7s.cloudfront.net/65df83b3c4d68.svg'

type EventCardProps = {
  sectionPosition?: number
  dataCreative?: string
  loadingImage?: 'lazy' | 'eager' | undefined
  isLateralized?: boolean
  isHover?: boolean
  fullWidth?: boolean
  ImageComponent?: React.ElementType<React.ImgHTMLAttributes<HTMLImageElement>>
  reportButton?: boolean
  onReport?: (name: string, url: string) => void
} & SearchEvent

function EventCard({
  name,
  location,
  images,
  url,
  duration_type,
  start_date,
  start_date_formats,
  end_date,
  end_date_formats,
  id,
  sectionPosition,
  dataCreative,
  type,
  organizer,
  loadingImage = 'lazy',
  isLateralized = false,
  ImageComponent = 'img',
  fullWidth = false,
  isHover = true,
  reportButton = false,
  onReport,
}: EventCardProps) {
  const buildTemplate = () => {
    if (type === eventType.ondemand) {
      return (
        <div className={styles.InfoContainer}>
          <div className={styles.LogoWrapper}>
            <IconSymplaPlay className={styles.LogoWrapperSvg} />
          </div>
          <h3 className={styles.InfoTitle}>{name}</h3>
          <p className={styles.LocationStyle}>{`Por ${organizer?.name}`}</p>
        </div>
      )
    }

    const classNameInfo = classNames({
      [styles.InfoContainer]: true,
      [styles.InfoContainerLateralized]: isLateralized,
      [styles.CardStyleFullWidth]: fullWidth,
    })

    return (
      <div className={classNameInfo}>
        <h3 className={styles.InfoTitle}>{name}</h3>
        <p className={styles.LocationStyle}>
          {type === eventType.online
            ? 'Evento Online'
            : `${location?.name} - ${location?.city}, ${location?.state}`}
        </p>
        <div className={styles.DateStyle}>
          <DateFormatter
            startDate={start_date_formats?.pt || ''}
            endDate={end_date_formats?.pt || ''}
            durationType={duration_type}
            rawStartDate={start_date}
            rawEndDate={end_date}
          />
        </div>
      </div>
    )
  }

  const className = classNames({
    [styles.CardStyle]: true,
    [styles.CardStyleLateralized]: isLateralized,
    [styles.CardStyleIsHover]: isHover,
    [styles.CardStyleFullWidth]: fullWidth,
  })

  function handleReportClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault()

    onReport?.(name, url)
  }

  return (
    <a
      href={url}
      className={`sympla-card ${className}`}
      data-bannerid={`${id}`}
      data-name={`${name}`}
      data-position={`${sectionPosition}`}
      data-creative={`${dataCreative}`}
      id={`${id}`}
    >
      <ImageComponent
        className={`${styles.CardImg} ${isLateralized ? styles.CardImgLateralized : ''}`}
        src={images?.xs || DEFAULT_IMAGE}
        alt={`Banner ${name}`}
        loading={loadingImage}
      />
      {buildTemplate()}

      {reportButton && (
        <Button
          type="button"
          variant="text"
          upperCase={false}
          onClick={handleReportClick}
        >
          <IconReport width={16} height={16} /> Denunciar evento
        </Button>
      )}
    </a>
  )
}

export default EventCard
