import React from 'react'
import {
  dayMonthDateFormat,
  fullDateFormat,
  convertMilliseconds,
} from './dateFormat'

import * as styles from './DateFormatter.css'

type DateFormatterProps = {
  startDate: string
  endDate: string
  size?: 'sm' | 'md'
  durationType?: string
  rawStartDate: string
  rawEndDate: string
  variant?: 'neutral' | 'contrast'
}

const DateFormatter: React.FC<DateFormatterProps> = ({
  startDate,
  endDate,
  size = 'sm',
  durationType = '',
  rawStartDate = '',
  rawEndDate = '',
  variant = 'contrast',
}) => {
  const buildTemplate = () => {
    const options = {
      single: function Single() {
        return (
          <div
            className={`${styles.DateStyle[variant]} ${styles.DateSize[size]}`}
          >
            {fullDateFormat(startDate)}
          </div>
        )
      },
      multiple: function Multiple() {
        return (
          <>
            <div
              className={`${styles.DateStyle[variant]} ${styles.DateSize[size]}`}
            >
              {dayMonthDateFormat(startDate)} a {dayMonthDateFormat(endDate)}
            </div>
          </>
        )
      },
    }

    const checkDate = convertMilliseconds(
      new Date(rawEndDate).getTime() - new Date(rawStartDate).getTime()
    )

    return checkDate?.day > 0
      ? options['multiple']()
      : options['single']() || null
  }

  return (
    <div
      className={`${styles.DateContainer} ${styles.DateContainerSize[size]}`}
    >
      {durationType !== 'hidden' && buildTemplate()}
    </div>
  )
}

export default DateFormatter
