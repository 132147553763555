export const checkCardPreviousView = (eventID: number, objectCardIDs: { [key: string]: string }) => {
  const { recents } = objectCardIDs;
  if (recents.length > 0) {
    try {
      return recents.includes(String(eventID));
    } catch {
      return false;
    }
  }
  return false;
};
