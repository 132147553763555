import React from 'react'
import IconLoading from '../../icons/action/iconLoading'
import * as styles from './Button.css'
import classNames from 'classnames'

type ButtonProps = {
  variant?: 'solid' | 'outline' | 'text'
  size?: 'micro' | 'small' | 'medium' | 'large'
  color?: 'brand' | 'analogue' | 'contrast' | 'urgent' | 'neutral'
  loading?: boolean
  disabled?: boolean
  icon?: React.ReactNode
  fullWidth?: boolean
  fullWidthOnMobile?: boolean
  upperCase?: boolean
  rounded?: boolean
  children?: React.ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({
  variant = 'solid',
  size = 'small',
  color = 'brand',
  loading,
  disabled,
  icon,
  fullWidth,
  fullWidthOnMobile,
  upperCase = true,
  children,
  rounded,
  ...props
}) => {
  const className = classNames({
    [styles.Base]: true,
    [styles.TypeSolid]: variant === 'solid',
    [styles.TypeOutline]: variant === 'outline',
    [styles.TypeText]: variant === 'text',
    [styles.SizeMicro]: size === 'micro',
    [styles.SizeSmall]: size === 'small',
    [styles.SizeMedium]: size === 'medium',
    [styles.SizeLarge]: size === 'large',
    [styles.FullWidthOnMobile]: fullWidthOnMobile,
    [styles.FullWidth]: fullWidth,
    [styles.Uppercase]: upperCase,
    [styles.Rounded]: rounded,
    [styles.IconButton]: icon && !children,
  })

  const colorsClassName =
    variant === 'solid'
      ? styles.colorsSolidStyle[color]
      : styles.colorsTextStyle[color]

  return (
    <button
      className={`${className} ${colorsClassName}`}
      disabled={disabled || loading}
      {...props}
    >
      {!!icon && !loading && icon}
      {!!loading && <IconLoading />}
      {children}
    </button>
  )
}

export default Button
