const dayMonthDateFormat = (date: string) => {
	const regexWeekDay = /.*, /
	const regexYear = / -.*/
	return date
		.replace(regexWeekDay, '')
		.replace(regexYear, '')
		.replace(' ', ' de ')
}

const fullDateFormat = (date: string) => {
	const daysWeek: { [key: string]: string } = {
		SEG: 'Segunda',
		TER: 'Terça',
		QUA: 'Quarta',
		QUI: 'Quinta',
		SEX: 'Sexta',
		SAB: 'Sábado',
		DOM: 'Domingo',
	}
	const partDate = date.split(' ')

	const dayWeek = partDate[0].replace(',', '').toUpperCase()
	const day = partDate[1]
	const month = partDate[2]
	const time = partDate[partDate.length - 1]

	const newDate = `${daysWeek[dayWeek]}, ${day} de ${month} às ${time}`

	return newDate
}

const convertMilliseconds = (milliseconds: number) => {
	let seconds = Math.floor(milliseconds / 1000)
	let minute = Math.floor(seconds / 60)
	seconds = seconds % 60
	let hour = Math.floor(minute / 60)
	minute = minute % 60
	const day = Math.floor(hour / 24)
	hour = hour % 24
	return {
		day: day,
		hour: hour,
		minute: minute,
		seconds: seconds,
	}
}

export { dayMonthDateFormat, fullDateFormat, convertMilliseconds }
