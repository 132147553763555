/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location } from 'location.d';
import { checkCardPreviousView } from 'utils/checkCardPreviousView';
import { getHoursDiference } from 'utils/timeFunctions';

interface TrackingArgs {
  trackingPrefix?: string;
  matrizTemplate: string;
  componentTemplate?: string;
  dataLocation: Location | null;
  serviceParams?: any;
  cardType?: string;
  type?: string;
  filters?: any;
  payloadCard?: any;
  cardPreviousView?: any;
  templateType?: string;
}

/**
 * Cria propriedades para rastreamento de matriz baseado na posição do card e nos argumentos fornecidos.
 * @param cardPosition - A posição do card na matriz.
 * @param args - Argumentos necessários para gerar as propriedades de rastreamento.
 * @returns Um objeto contendo as propriedades de rastreamento ou um objeto vazio se o tipo de card não for reconhecido.
 */
export const createPropsToTrackingMatrix = (cardPosition: number, args: TrackingArgs): Record<string, any> => {
  const {
    trackingPrefix = '',
    matrizTemplate = '',
    componentTemplate = '',
    dataLocation,
    cardType = '',
    serviceParams,
    payloadCard,
    cardPreviousView,
    templateType = '',
    type,
    filters,
  } = args;

  const getSlugFilter = () => (dataLocation?.slug ? `.filter_city-${dataLocation.slug}` : '');
  const getStartDate = () => (payloadCard?.start_date ? `.start-${getHoursDiference(payloadCard.start_date)}` : '');
  const getPreviousView = () => (cardPreviousView && checkCardPreviousView(payloadCard?.id, cardPreviousView) ? '.previous-view' : '');

  const trackingOptions: Record<string, () => Record<string, any>> = {
    'events-gallery': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${matrizTemplate}.component-${componentTemplate}${getSlugFilter()}.response_type-${templateType}${
        serviceParams?.sort ? `.sort-${serviceParams.sort}` : ''
      }${serviceParams?.type ? `.type-${serviceParams.type}` : ''}${getStartDate()}${getPreviousView()}.provider-discovery_next`,
    }),
    'collection-card': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.response_type-${templateType}${getSlugFilter()}.provider-discovery_next`,
    }),
    'banner-card': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.response_type-${templateType}${getSlugFilter()}.provider-discovery_next`,
    }),
    'banner-login': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.response_type-${templateType}${getSlugFilter()}.provider-discovery_next`,
    }),
    'banner-oss': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.response_type-${templateType}${getSlugFilter()}.provider-discovery_next`,
    }),
    'event-card': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.component-${componentTemplate}${getSlugFilter()}.response_type-${templateType}${
        serviceParams?.sort ? `.sort-${serviceParams.sort}` : ''
      }${serviceParams?.type ? `.type-${serviceParams.type}` : ''}${getStartDate()}${getPreviousView()}.provider-discovery_next`,
    }),
    'recent-card': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.component-${componentTemplate}${getSlugFilter()}.response_type-${templateType}.sort-recent${
        serviceParams?.type ? `.type-${serviceParams.type}` : ''
      }${getStartDate()}${getPreviousView()}.provider-discovery_next`,
    }),
    sponsored: () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.component-${componentTemplate}.response_type-${templateType}${
        payloadCard?.type ? `.type-${String(payloadCard.type).toLowerCase()}` : ''
      }${getStartDate()}.provider-discovery_next`,
    }),
    'city-card': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.component-${componentTemplate}.response_type-${templateType}${getSlugFilter()}
      .provider-discovery_next`,
    }),
    'venue-card': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.component-${componentTemplate}.response_type-${templateType}${getSlugFilter()}
      .provider-discovery_next`,
    }),
    'organizer-card': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}-${matrizTemplate}.component-${componentTemplate}.response_type-${templateType}${getSlugFilter()}
      .provider-discovery_next`,
    }),
    'event-card-search': () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}${serviceParams?.url_params?.city ? `.city-${serviceParams.url_params.city.slug}` : ''
      }${serviceParams?.url_params?.date ? `.date-${serviceParams.url_params.date.slug}` : ''}${
        serviceParams?.url_params?.collection ? `.collection-${serviceParams.url_params.collection.slug}` : ''
      }${componentTemplate ?? serviceParams?.url_params?.component ? `.component-${componentTemplate ?? serviceParams.url_params.component.slug}`
        : ''}${serviceParams?.url_params?.matrix ? `.matriz-${serviceParams.url_params.matrix.slug}` : ''
      }${dataLocation?.slug ? `.filter_city-${dataLocation.slug}` : ''}${
        payloadCard?.type ? `.type-${String(payloadCard.type).toLowerCase()}` : ''
      }${type ? `.response_type-${type}-card` : ''}${getStartDate()}${getPreviousView()}${
        filters?.date ? `.filter_range-${filters.date}` : ''
      }${filters?.sort ? `.sort-${filters.sort}` : ''}.provider-discovery_next`,
    }),
  };

  return trackingOptions[cardType]?.() || {};
};

interface PayloadCard {
  type: string
  start_date: string
}

export const createPropsToTrackingLiveSearch = (cardPosition: number, payloadCard: PayloadCard, args: Partial<TrackingArgs>) => {
  const {
    trackingPrefix = '',
    dataLocation = null,
    type = '',
  } = args;
  // console.log('< createPropsToTrackingGrid > ', cardPosition, payloadCard, dataLocation)

  const options = {
    search: () => ({
      sectionPosition: `${cardPosition + 1}`,
      dataCreative: `.${trackingPrefix}`
      + '.by_term-live-search'
      + `${dataLocation?.slug ? `.filter_city-${dataLocation.slug}` : ''}`
      + `${payloadCard?.type ? `.type-${String(payloadCard.type).toLowerCase()}` : ''}` /** onlune, normal */
      + `${type ? `.response_type-${type}` : ''}`
      + `${payloadCard?.start_date ? `.start-${getHoursDiference(payloadCard.start_date)}` : ''}`,
    }),
  };

  if (trackingPrefix in options) {
    return options[trackingPrefix as keyof typeof options]?.() || {};
  }

  return {};
};
